<template>
  <v-app>
    <!-- <v-navigation-drawer app>
      
    </v-navigation-drawer>-->

    <AppBar />
    <NavigationDrawer />
    <v-main id="app_body">
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import AppBar from "./components/navigation/AppBar.vue";
import NavigationDrawer from "./components/navigation/NavigationDrawer.vue";
export default {
  components: { AppBar, NavigationDrawer },
  methods: {
    async getContent() {
      this.$prismic.client.getSingle("header_navbar").then((navData) => {
        console.log(navData.data);
        this.$store.dispatch("updateNavDataAction", navData.data);
      });
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style>
#app_body {
  width: 100%;
  max-width: 1200px !important;
  margin: 0 auto;
}
.v-toolbar__content,
.v-toolbar__extension {
  max-width: 1200px;
  margin: 0 auto;
}

.nondraggable {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>