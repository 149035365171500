<template>
  <div id="page_services">
    <div class="content" v-if="doc != null">
      <prismic-rich-text
        :html-serializer="htmlSerializer"
        :field="doc.data.h1"
      />

      <SliceZone
        :slices="doc.data.body"
        :resolver="({ sliceName }) => slices[sliceName]"
      />
    </div>
    <div class="skeleton"></div>
  </div>
</template>

<script>
import SliceZone from "vue-slicezone";
import SimpleParagraph from "../components/slices/SimpleParagraph.vue";
import htmlSerializer from "../assets/htmlSerializer";

export default {
  components: { SliceZone },

  data: () => ({
    htmlSerializer: htmlSerializer,
    doc: null,
    // Add your Slices to a "slices" object, which
    // will be used in the resolver function.
    slices: { SimpleParagraph },
  }),
  methods: {
    async getContent() {
      this.$prismic.client.getSingle("page_services").then((pageData) => {
        this.doc = pageData;
      });
    },
  },
  created() {
    // Call the API query method
    this.getContent();
  },
};
</script>

<style>
</style>