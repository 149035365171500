<template>
  <div>
    <prismic-rich-text
      :field="slice.primary.title"
      v-if="slice.primary.title"
      :html-serializer="htmlSerializer"
    />
    <prismic-rich-text
      :field="slice.primary.subtitle"
      v-if="slice.primary.subtitle"
      :html-serializer="htmlSerializer"
    />
    <prismic-rich-text
      :field="slice.primary.description"
      v-if="slice.primary.description"
      :html-serializer="htmlSerializer"
    />

    <prismic-image :field="slice.primary.image" />
  </div>
</template>

<script>
import htmlSerializer from "../../assets/htmlSerializer";

export default {
  name: "SimpleParagraph",
  props: {
    slice: Object,
  },
  data: () => ({
    htmlSerializer: htmlSerializer,
  }),
};
</script>

<style>
</style>