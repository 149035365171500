// The Link Resolver takes a Prismic document as its argument
export default function (doc) {
  // Then it executes a switch to determine how to treat the item
  if (doc.type === "page_home") {
    // If you have a singleton 'homepage' document, you return the root
    return "/";
  }
  if (doc.type === "page_services") {
    // For a 'post', you could use the post's Unique ID (UID)
    return "/dienste";
  }
  // For other document types, just use the UID
  if (doc.type === "about") {
    // For a 'post', you could use the post's Unique ID (UID)
    return "/ueber-uns";
  }
  if (doc.type === "contact") {
    // For a 'post', you could use the post's Unique ID (UID)
    return "/kontakt";
  }
  if (doc.type === "jobs") {
    // For a 'post', you could use the post's Unique ID (UID)
    return "/kariere";
  }
  // Otherwise, return a 404
  return "/404";
}