import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const navModule = {
  state: () => ({
    logo: null,
    links: null,
    drawer: false,
  }),
  mutations: {
    updateLogo(state, logo) {
      state.logo = logo;
    },
    updateLinks(state, links) {
      state.links = links;
    },
    invertDrawer(state) {
      state.drawer = !state.drawer;
    }
  },
  actions: {
    updateNavDataAction(context, navData) {
      context.commit("updateLogo", navData.logo);
      let links = [];
      navData.body.forEach(link => {
        links.push(link.primary);
      });
      context.commit("updateLinks", links);
    },
    invertDrawerAction(context) {
      context.commit("invertDrawer");
    }
  },
  getters: {
    getNavLinks(state) {
      return state.links;
    },
    getNavLogo(state) {
      return state.logo;
    },
    getDrawer(state) {
      return state.drawer;
    }
  }
}

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    navModule: navModule
  }
})
