<template>
  <div class="firmenwerte">
    <prismic-rich-text
      :html-serializer="htmlSerializer"
      :field="slice.primary.values_title"
    />
    <!--<div v-for="(item, i) in slice.items" :key="i">
      <prismic-rich-text :field="item.value_name"  :html-serializer="htmlSerializer"/>
      <prismic-rich-text :field="item.value_description"  :html-serializer="htmlSerializer"/>
      <prismic-image :field="item.value_image" />
    </div>-->
    <v-row align="center">
      <v-item-group v-model="window" class="shrink mr-6" mandatory tag="v-flex">
        <v-item
          v-for="(item, n) in slice.items"
          :key="`dot${n}`"
          v-slot="{ active, toggle }"
        >
          <div>
            <v-btn :input-value="active" icon @click="toggle">
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </div>
        </v-item>
      </v-item-group>

      <v-col>
        <v-window v-model="window" class="elevation-1" vertical>
          <v-window-item v-for="(item, i) in slice.items" :key="`window${i}`">
            <v-card flat>
              <v-card-title>
                <!-- <v-avatar color="grey" class="mr-4"></v-avatar> -->
                <strong class="text-h6">
                  <prismic-rich-text
                    :field="item.value_name"
                    :html-serializer="htmlSerializer"
                /></strong>
                <!-- <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-account</v-icon>
                  </v-btn> -->
              </v-card-title>
              <v-card-text>
                <div class="item_group_content">
                  <prismic-image
                    :field="item.value_image"
                    class="item_group_image"
                  />
                  <prismic-rich-text
                    class="item_group_text"
                    :field="item.value_description"
                    :html-serializer="htmlSerializer"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import htmlSerializer from "../../assets/htmlSerializer";

export default {
  data: () => ({
    htmlSerializer: htmlSerializer,
    window: 0,
  }),
  name: "Firmenwerte",
  props: {
    slice: Object,
  },
};
</script>

<style>
.item_group_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.item_group_text {
  min-width: 300px;
  flex: 1 1;
}

.item_group_image {
  margin-right: 5px;
  flex: 0 0;
}
</style>