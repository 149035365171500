<template>
  <div id="page_contact">
    <div class="content" v-if="doc != null">
      <prismic-rich-text :html-serializer="htmlSerializer" :field="doc.data.h1" />
      <SliceZone
        :slices="doc.data.body"
        :resolver="({ sliceName }) => slices[sliceName]"
      />
    </div>
  </div>
</template>

<script>

import htmlSerializer from "../assets/htmlSerializer";
import SliceZone from "vue-slicezone";
import SimpleParagraph from "../components/slices/SimpleParagraph.vue";

export default {
  components: {  SliceZone},
  data() {
    return {
      htmlSerializer: htmlSerializer,
      doc: null,
      slices: { SimpleParagraph },
    };
  },
  methods: {
    async getContent() {
      this.$prismic.client.getSingle("contact").then((pageData) => {
        this.doc = pageData;
      });
    },
  },
  created() {
    // Call the API query method
    this.getContent();
  },
};
</script>

<style>

</style>
