import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrismicVue from '@prismicio/vue'
import linkResolver from './link-resolver'
import vuetify from './plugins/vuetify'

const endpoint = 'https://sps-security.prismic.io/api/v2';

Vue.config.productionTip = false

Vue.use(PrismicVue, { endpoint, linkResolver });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
