function htmlSerializer(type, element, content, children) {
    // If element is a list item,
    if (type === "heading1") {
        return `<h1 class="text-h2">${children.join("")}</h1>`;
    }
    else if (type === "heading2") {
        return `<h2 class="text-h3">${children.join("")}</h2>`;
    }
    else if (type === "heading3") {
        return `<h3 class="text-h4">${children.join("")}</h3>`;
    }
    else if (type === "heading4") {
        return `<h4 class="text-h5">${children.join("")}</h4>`;
    }
    else if (type === "heading5") {
        return `<h5 class="text-h6">${children.join("")}</h5>`;
    }
    /// Otherwise, return null.
    return null;
}

export default htmlSerializer;