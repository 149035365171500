<template>
  <div class="partners_section mt-10">
    <prismic-rich-text
      :field="slice.primary.partners_title"
      :html-serializer="htmlSerializer"
    />
    <div class="partner_containers">
      <div class="partner_container" v-for="(item, i) in slice.items" :key="i">
        <v-card max-width="350" outlined flat min-width="300" class="mb-5 mx-2">
          <v-card-title>
            <prismic-rich-text
              :field="item.partner_name"
              :html-serializer="htmlSerializer"
          /></v-card-title>
          <v-card-text>
            <div class="partner_logo">
              <prismic-link :field="item.link">
                <prismic-image
                  :field="item.logo"
                  height="250px"
                  class="nondraggable"
                />
              </prismic-link>
            </div>

            <prismic-rich-text
              :field="item.partner_description"
              :html-serializer="htmlSerializer"
            />
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import htmlSerializer from "../../assets/htmlSerializer";

export default {
  data: () => ({
    htmlSerializer: htmlSerializer,
  }),
  name: "CustomerLogos",
  props: {
    slice: Object,
  },
};
</script>

<style>
.partner_containers {
  display: flex;
  flex-wrap: wrap;
}
.partner_container {
  flex: 1 1 0px;
}
.partner_logo {
 text-align: center;
}
</style>