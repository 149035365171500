<template>
  <div class="mb-10">
    <v-card max-width="500px" flat outlined>
      <v-card-title>
        <prismic-rich-text :field="slice.primary.title" />
      </v-card-title>
      <v-card-subtitle>
        <prismic-rich-text :field="slice.primary.subtitle" />
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card-text>
            <prismic-rich-text :field="slice.primary.text" />
            <prismic-image :field="slice.primary.image" />
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import htmlSerializer from "../../assets/htmlSerializer";

export default {
  data: () => ({
    htmlSerializer: htmlSerializer,
    show: false,
  }),
  name: "ContentCard",
  props: {
    slice: Object,
  },
};
</script>

<style>
</style>