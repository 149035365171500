<template>
  <div class="mb-5">
    <v-banner color="red" class="white--text" single-line sticky>
      <prismic-rich-text :field="text"  class="emergency_banner"/>
    </v-banner>
  </div>
</template>

<script>
export default {
  name: "EmergencyNumber",
  props: ["text"],
};
</script>

<style>
.emergency_banner p {padding:0; margin:0}
</style>