<template>
  <div class="mb-10">
    <prismic-rich-text
      :field="slice.primary.title"
      :html-serializer="htmlSerializer"
    />
    <prismic-rich-text
      :field="slice.primary.description"
      :html-serializer="htmlSerializer"
    />
    <v-carousel>
      <v-carousel-item
        v-for="(item, i) in slice.items"
        :key="i"
        :src="item.image.url"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import htmlSerializer from "../../assets/htmlSerializer";

export default {
  data: () => ({
    htmlSerializer: htmlSerializer,
  }),
  name: "CardsCarousel",
  props: {
    slice: Object,
  },
};
</script>

<style>
</style>