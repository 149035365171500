<template>
  <v-navigation-drawer
    temporary
    app
    v-model="drawer"
    color="primary"
    v-if="drawer"
  >
    <v-list-item>
      <v-list-item-content>
        <router-link to="/">
          <v-img src="../../assets/logo_white.png" width="200px"></v-img>
        </router-link>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
        v-for="(link, i) in navLinks"
        :key="i"
        :to="$prismic.asLink(link.path)"
      >
        <v-list-item-icon>
          <v-icon class="white--text">{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text">{{
            link.name
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({}),

  computed: {
    ...mapGetters({
      navLinks: "getNavLinks",
      navLogo: "getNavLogo",
      drawerGetter: "getDrawer",
    }),
    drawer: {
      get() {
        return this.drawerGetter;
      },
      set(val) {
        if (!val) {
          this.$store.dispatch("invertDrawerAction");
        }
      },
    },
  },
};
</script>

<style>
</style>