<template>
  <div class="timeline">
    <prismic-rich-text
      :field="slice.primary.timeline_title"
      :html-serializer="htmlSerializer"
    />
    <v-timeline>
      <template v-for="(item, i) in slice.items">
        <v-timeline-item right :icon="item.icon" color="secondary" :key="i">
          <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold`"
              v-text="item.year"
            ></span>
          </template>
          <div class="py-4">
            <prismic-rich-text :field="item.description" />
          </div>
        </v-timeline-item>
      </template>
    </v-timeline>
  </div>
</template>

<script>
import htmlSerializer from "../../assets/htmlSerializer";

export default {
  data: () => ({
    htmlSerializer: htmlSerializer,
  }),
  name: "SimpleParagraph",
  props: {
    slice: Object,
  },
};
</script>

<style>
</style>