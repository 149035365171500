<template>
  <v-app-bar
    id="vue-app-bar"
    color="primary"
    app
    
    :prominent="!$vuetify.breakpoint.smAndDown"
  >
  <!-- v-if="navLogo != null && navLinks != null" -->
    <v-app-bar-nav-icon
      class="white--text"
      v-if="$vuetify.breakpoint.smAndDown"
      @click="$store.dispatch('invertDrawerAction')"
      aria-label="Mobile Menu"
    ></v-app-bar-nav-icon>

    <v-app-bar-title>
      <router-link to="/">
        <v-img src="../../assets/logo_white.png" :width="$vuetify.breakpoint.smAndDown?'140px':'300px'"></v-img>
      </router-link>
    </v-app-bar-title>
    <template v-slot:extension v-if="!$vuetify.breakpoint.smAndDown">
      <v-tabs align-with-title color="white">
        <v-tab
          v-for="(link, i) in navLinks"
          :key="i"
          class="white--text"
          :to="$prismic.asLink(link.path)"
        >
          <v-icon class="white--text" left>{{ link.icon }}</v-icon>
          {{ link.name }}
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({ navLinks: "getNavLinks", navLogo: "getNavLogo" }),
  },
};
</script>

<style scoped lang="scss">
.appbar_content {
  display: none;
}
$app-bar-scrolled-title-padding-bottom: 0px !important;
</style>