<template>
  <div id="page_home">
    <div class="content" v-if="doc != null">
      <EmergencyNumber :text="doc.data.emergency_number" />

      <prismic-rich-text :html-serializer="htmlSerializer" :field="doc.data.h1" />
      <SliceZone
        :slices="doc.data.body"
        :resolver="({ sliceName }) => slices[sliceName]"
      />
    </div>
    <div class="skeleton"></div>
  </div>
</template>

<script>
import SliceZone from "vue-slicezone";
import CardsCarousel from "../components/slices/CardsCarousel.vue";
import EmergencyNumber from "../components/slices/EmergencyNumber.vue";
import CustomerLogos from "../components/slices/CustomerLogos.vue";
import Firmenwerte from "../components/slices/Firmenwerte.vue";
import htmlSerializer from "../assets/htmlSerializer";

export default {
  components: { SliceZone, EmergencyNumber },
  data() {
    return {
      htmlSerializer:htmlSerializer,
      doc: null,
      slices: {
        CardsCarousel,
        CustomerLogos,
        Firmenwerte
      },
    };
  },
  methods: {
    async getContent() {
      this.$prismic.client.getSingle("page_home").then((pageData) => {
        this.doc = pageData;
      });
    },
  },
  created() {
    // Call the API query method
    this.getContent();
  },
};
</script>

<style>
</style>
